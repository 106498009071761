import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <div style={{ maxWidth: `1200px`, marginBottom: `1.45rem` , 'marginLeft': '4px', 'marginRight': '4px'}} align="left">
    <h1>404</h1>
    </div>
  </Layout>
)

export default NotFoundPage
